import React from "react";

const About = () => {
  return (
    <div className="w-full h-auto px-20 bg-gray-100">
      <div className="flex flex-col lg:flex-row justify-between items-center gap-16">
        {/* Left Section with Text */}
        <div className="lg:w-1/2 text-center lg:text-left">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6">
            About Us
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            We are committed to providing you with the best travel experiences
            and premium hotel stays at unbeatable prices. Our team works around
            the clock to ensure you have everything you need to make your
            journey as smooth as possible.
          </p>
          <p className="text-lg text-gray-600">
            With years of expertise, we offer curated travel packages,
            exceptional customer service, and exclusive deals to help you travel
            in style and comfort. Let us help you plan your next unforgettable
            adventure.
          </p>
        </div>

        {/* Right Section with Image */}
        <div className="lg:w-1/2 md:h-[400px]">
          <div
            className="w-full h-full md:bg-cover bg-contain bg-center rounded-lg shadow-xl"
            style={{
              backgroundImage:
                "url('https://img.freepik.com/free-photo/beautiful-luxury-outdoor-swimming-pool-hotel-resort_74190-7433.jpg?semt=ais_hybrid')",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default About;
