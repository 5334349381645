import React, { useState, useEffect } from "react";
import Hero from "./Hero";
import Features from "./Feature";
import About from "./About";
import ReactGA from "react-ga4";
import { IoMdClose } from "react-icons/io";

const Popup = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1000]">
      <div className=" md:w-[80vh] relative overflow-hidden w-[50vh] h-[50vh]  md:h-[80vh] rounded-lg shadow-lg">
        <img className="w-full h-full" src="/foodpopup.js.jpg" alt="Popup" />
        <span className="absolute bg-white rounded-full p-2 top-5 right-5">
          <IoMdClose className="text-xl" />
        </span>
      </div>
    </div>
  );
};

const Homepage = () => {
  useEffect(() => {
    ReactGA.initialize("G-TMEE7YDQHY");
    ReactGA.send("pageview");
  }, []);

  const handleonclick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://bit.ly/ggnowww", "_blank");
  };
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div onClick={handleonclick}>
      {showPopup && (
        <div>
          <Popup />
        </div>
      )}
      <Hero />
      <Features />
      <About />
      <div className="w-full h-auto bg-black md:flex justify-between items-center p-10 mt-32">
        <h1 className="text-white md:text-xl text-sm">
          Copyright © 2024 BetEscape - All Rights Reserved.
        </h1>
        <h1 className="text-white text-xl md:mt-0 mt-5">Powered by phantom</h1>
      </div>
    </div>
  );
};

export default Homepage;
