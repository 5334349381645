import React from "react";
import Marquee from "react-fast-marquee";

const Hero = () => {
  return (
    <div
      className="w-full h-[80vh] bg-no-repeat bg-cover relative"
      style={{
        backgroundImage:
          "url('https://t3.ftcdn.net/jpg/00/29/13/38/360_F_29133877_bfA2n7cWV53fto2BomyZ6pyRujJTBwjd.jpg')",
      }}
    >
      {/* Background Overlay */}
      <div className="absolute inset-0 w-full h-[80vh] bg-black bg-opacity-50"></div>

      {/* Hero Content */}
      <div className="absolute z-50 top-1/3 left-5 md:left-20 px-4 md:px-0">
        <div className="w-full md:w-2/3">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white leading-tight">
            Discover Luxury Stays
          </h1>
          <h2 className="text-lg md:text-2xl lg:text-3xl font-medium text-gray-200 mt-4">
            Book your next adventure with unbeatable deals
          </h2>
          <div className="flex flex-col md:flex-row gap-5 md:gap-10 mt-8">
            <button className="bg-white text-red-700 text-xs md:text-sm font-bold px-8 md:px-12 py-2 md:py-3 rounded-full shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
              Explore Hotels
            </button>
            <button className="bg-red-600 text-white text-xs md:text-sm font-bold px-8 md:px-12 py-2 md:py-3 rounded-full shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
              Reserve Now
            </button>
          </div>
        </div>
      </div>

      {/* Marquee */}
      <Marquee autoFill className="bg-red-500 text-gray-200 p-2 fixed top-0 z-[35]">
        <span className="mx-4 text-xs md:text-lg font-semibold">
          🏨 Early Bird Offer: Save up to 40% on luxury stays
        </span>
        <span className="mx-4 text-xs md:text-lg font-semibold">
          🌟 Premium deals for premium experiences
        </span>
        <span className="mx-4 text-xs md:text-lg font-semibold">
          ✈️ Adventure awaits—book now!
        </span>
      </Marquee>
    </div>
  );
};

export default Hero;
