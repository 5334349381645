import React from "react";

const Features = () => {
  return (
    <div className="w-full py-16 bg-gray-50 text-white">
      <div className="text-center mb-10">
        <h2 className="text-3xl md:text-4xl text-black font-bold">Why Choose Us?</h2>
      </div>

      <div className="flex justify-center gap-8">
        {/* Feature 1 */}
        <div className="text-center max-w-xs bg-cover bg-center rounded-lg shadow-xl" 
             style={{ backgroundImage: "url('https://images.pexels.com/photos/261102/pexels-photo-261102.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260')" }}>
          <div className="bg-black bg-opacity-40 p-6 rounded-lg">
            <div className="text-4xl mb-4">
              <i className="fas fa-bed"></i>
            </div>
            <h3 className="text-xl font-semibold">Luxury Stays</h3>
            <p className="text-sm mt-2">Premium hotels, unforgettable experiences.</p>
          </div>
        </div>

        {/* Feature 2 */}
        <div className="text-center max-w-xs bg-cover bg-center rounded-lg shadow-xl" 
             style={{ backgroundImage: "url('https://cdn.pixabay.com/photo/2015/11/06/11/45/interior-1026452_640.jpg')" }}>
          <div className="bg-black bg-opacity-40 p-6 rounded-lg">
            <div className="text-4xl mb-4">
              <i className="fas fa-tags"></i>
            </div>
            <h3 className="text-xl font-semibold">Best Deals</h3>
            <p className="text-sm mt-2">Exclusive discounts for members.</p>
          </div>
        </div>

        {/* Feature 3 */}
        <div className="text-center max-w-xs bg-cover bg-center rounded-lg shadow-xl" 
             style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/00/09/21/15/360_F_9211505_d4hxfNtPeTfgt7AeGmoO7u79P2hwxkoQ.jpg')" }}>
          <div className="bg-black bg-opacity-40 p-6 rounded-lg">
            <div className="text-4xl mb-4">
              <i className="fas fa-globe"></i>
            </div>
            <h3 className="text-xl font-semibold">Global Reach</h3>
            <p className="text-sm mt-2">Find your next adventure worldwide.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
